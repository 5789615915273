<template>
  <b-container fluid="xl">
    <div v-show="items.length">
      <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="3">
        <b-col v-for="(item, index) in items" :key="index">
      <b-card class="xnshadow mb-4" :body-class="item.connected?'':'offlin'" header-tag="header" :header-class="item.stp?'text-warning':'text-white'" :header-bg-variant="item.connected?'success':'danger'">
        <template v-slot:header>
          <p class="mb-0">{{sitename}}<span class="ml-3 mr-4" v-b-tooltip.hover :title="item.mac">#{{item.chargerid}}</span>
          <img :src="item.sigid" style="width:20px;height:20px;" :alt="item.sigmsg" :title="item.sigmsg"/>
          <b-button style="position:absolute;right:16px" size="sm" variant="outline-primary" :disabled="item.connected==0" @click="docmd(1,item)">重启</b-button>
          </p>
        </template>
        <b-card-text>
          <b-row cols="2" class="mb-2 mr-2">
            <div><span class="btn-sm">{{item.connected?'数据时间':'离线时间'}}</span><span style="font-size:small;" @click="gotobeeptest(item)">{{item.connected?item.beep:item.ofline}}</span></div>
            <div><span class="btn-sm">用电抄表</span><span style="font-size:small;">{{item.pow}}</span></div>
            <div><span class="btn-sm" :class="item.ac?'text-danger':''">{{acs[item.ac]}}</span><span style="font-size:small;">{{localtime}}</span></div>
            <div><span class="btn-sm"><span @click="docmd(18,item)">设备温度</span></span><span style="font-size:small;">{{item.tp[0]}}/{{item.tp[1]}}/{{item.tp[2]}}</span></div>
            <div class="text-secondary"><span class="btn-sm">电压电流</span><span style="font-size:small;"><span @click="changepvnv(index)">{{item.pvv}}</span>/{{item.pii}}</span></div>
            <div class="text-secondary"><span class="btn-sm">急停开箱</span><span style="font-size:small;">{{item.stp?'按下':'正常'}}/{{item.ta==1?'已开':'已关'}}</span></div>
          </b-row>
          <b-row cols="2" style="font-size:small;">
            <div class="pt-2 pb-2">
            <b-col v-for="(left, id0) in item.left" :key="id0" style="padding-left:0px;padding-right:0px;">
              <div class="mb-2">
                <span class="mr-2">{{id0+1}}</span>
                <span :class="left.sw==2?'midline':''">
                <b-link href @click="showusr(left.openid,item)" class="text-decoration-none mr-1" :class="left.openid?'bg-primary text-white':''" :disabled="left.openid==''">{{left.openid?'有人':'无人'}}</b-link>
                <span>{{left.end}}</span>
                <b-link href @click="togglesw(id0,item,left.openid)" class="text-decoration-none btn-sm" :class="sts[left.sw]" :disabled="left.sw>1 || item.connected==0">{{sws[left.sw]}}</b-link>
                <span>{{left.pi}}</span>
                </span>
              </div>
            </b-col>
            </div>
            <div class="pt-2 pb-2">
            <b-col v-for="(right, id6) in item.right" :key="id6" style="padding-left:0px;padding-right:0px;">
              <div class="mb-2">
                <span class="mr-2">{{id6>2?id6-3:id6+7}}</span>
                <span :class="right.sw==2?'midline':''">
                <b-link href @click="showusr(right.openid,item)" class="text-decoration-none mr-1" :class="right.openid?'bg-primary text-white':''" :disabled="right.openid==''">{{right.openid?'有人':'无人'}}</b-link>
                <span>{{right.end}}</span>
                <b-link href @click="togglesw(6+id6,item,right.openid)" class="text-decoration-none btn-sm" :class="sts[right.sw]" :disabled="right.sw>1 || item.connected==0">{{sws[right.sw]}}</b-link>
                <span>{{right.pi}}</span>
                </span>
              </div>
            </b-col>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-show="maskenabled" class="fullmask" @click="closemask">
      <div class="navbar-fixed-top alert-danger text-left pt-2 pb-2" :show="alertmsg" @click="closemask">{{alertmsg}}</div>
      <div class="imgshow xnshadow">
        <img :src="bigheadimgurl" style="max-width:100vw;max-height:50vh"/>
      </div>
    </div>
  </b-container>
</template>

<style>
  a{color:rgba(0,102,0,0.5);}
  .offlin {background-color:#D3D3D3;filter:Alpha(Opacity=60);opacity:0.6;color:#666}
</style>
<script>
  let reloades = 0;
  export default {
    name: 'monitr0',
    created() {
      this.gettime();
    },
    mounted() {
      this.fetchData();
    },
    data() {
      return {
        maskenabled: false,
        bigheadimgurl: '',
        sitename: '(无名电站)',
        acs: ['本地时间','接地不良','火零反接','接地检测'],
        sws: ['已断','已通','禁用'],
        sts: ['text-info', 'text-danger', 'text-secondary'],
        localtime:'00:00:00',
        items: []
      };
    },
    methods: {
      gettime(){
        let nowtm = new Date();
        let hour = nowtm.getHours();
        let minu = nowtm.getMinutes();
        let secd = nowtm.getSeconds();
        if (hour<10) hour = '0'+hour;
        if (minu<10) minu = '0'+minu;
        if (secd<10) secd = '0'+secd;
        this.localtime = hour+':'+minu+':'+secd;
        if (this.$route.fullPath.indexOf('monitr0')>0) setTimeout(this.gettime, 900);
      },
      changepvnv(idx) {
        this.items[idx].pvv = this.items[idx].pnv;
      },
      closemask() {
        this.maskenabled = false;
        this.alertmsg = '';
      },
      showusr(oid,itm) {
        reloades = 0;
        let cid = itm.chargerid;
        let pow = itm.pow;
        let theapi = '/showsecuser?tm='+new Date().getTime()+'&app='+this.$route.params.app+'&oid='+oid+'&cid='+cid+'&id='+this.$route.params.id+'&sec='+this.$route.params.sec;
        this.axios.get(theapi).then(axresp => {
          this.bigheadimgurl = axresp.data.url;
          let powuse = pow-axresp.data.powstart;
          if (powuse<0) powuse = 0;
          powuse = (powuse/1000).toFixed(3);
          this.alertmsg = axresp.data.nickname+'['+axresp.data.mobile+']'+axresp.data.msg+',已用电:'+powuse;
          this.maskenabled = true;
        });
      },
      docmd(id,itm) {
        reloades = 0;
        if (id==1) itm.connected=0;
        let theapi = '/cmdsecure?tm='+new Date().getTime()+'&app='+this.$route.params.app+'&mac='+itm.mac+'&cmd='+id+'&id='+this.$route.params.id+'&sec='+this.$route.params.sec;
        this.axios.get(theapi).then();
      },
      togglesw(id,itm,openid) {
        reloades = 0;
        let val;
        if (id<6) {
          val = itm.left[id].sw*100+id;
        } else {
          val = itm.right[id-6].sw*100+id;
        }
        let theapi = '/cmdsecure?tm='+new Date().getTime()+'&app='+this.$route.params.app+'&mac='+itm.mac+'&cmd=14&val='+val+'&openid='+openid+'&id='+this.$route.params.id+'&sec='+this.$route.params.sec;
        this.axios.get(theapi).then();        
      },
      fetchData() {
        let theapi = '/monitors0?tm='+new Date().getTime()+'&r='+reloades;
        theapi = theapi + '&app='+this.$route.params.app;
        theapi = theapi + '&id='+this.$route.params.id;
        theapi = theapi + '&sec='+this.$route.params.sec;
        this.axios.get(theapi).then((axresp) => {
          if (axresp.status==200) {
            this.items = axresp.data.Items;
            this.sitename = axresp.data.sitename;
          } else {
            this.items = [];
          }
          if (this.$route.fullPath.indexOf('monitr0')>0 && reloades<10000) {
            let reloadms = 1000;
            if (this.items.length<4) {
              reloadms = 500;
            } else {
              if (reloades<100) {
                reloadms = 800;
              } else if (reloades<500) {
                reloadms = 1000;
              } else if (reloades<1000) {
                reloadms = 2000;
              } else {
                reloadms = 3000;
              }
            }
            setTimeout(this.fetchData, reloadms);
            reloades++;
          }
        });
      },
    }
  }
</script>
